<template>
  <section class="home-catalog">
    <div class="home-catalog__scroll" @click="scrollDown">
      <IconComponent name="scroll" />
    </div>
    <div class="home-catalog__content container">
      <div class="home-catalog__header" ref="catalog" id="catalog">
        <h2>Курсы</h2>
      </div>
      <div class="home-catalog__list">
        <span v-if="catalogs.length === 0">В настоящее время курсов нет</span>
        <HomeCatalogCardComponent
          v-else
          v-for="(catalog, i) in catalogs"
          :key="i"
          :catalog="catalog"
          :offers="catalog.offers"
        />
      </div>
    </div>
  </section>
</template>

<script>
import HomeCatalogCardComponent from "./HomeCatalogCardComponent.vue";
import IconComponent from "components/IconComponent.vue";
export default {
  name: "HomeCatalogListComponent",
  components: { HomeCatalogCardComponent, IconComponent },
  props: {
    catalogs: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    scrollDown() {
      let block = this.$refs.catalog;
      // Определение расстояния от начала страницы до нужного элемента
      let top = window.scrollY + block.getBoundingClientRect().y;
      // Перемотка
      window.scrollTo(0, top);
    },
  },
};
</script>

<style lang="stylus">
.home-catalog{
  padding var(--padding_30)
  position relative
  +below(1100px) {
    padding var(--padding_20)
  }
  +below(700px) {
    padding 0
  }

  &__scroll{
    background-image url("/static/images/scroll-bg.png")
    background-repeat no-repeat
    width 100%
    max-width 220px
    height 100%
    max-height 110px
    position absolute
    left: 50%;
    top: -69.52px;
    transform: translate(-50%);
    z-index: 150;
    display: flex;
    justify-content: center;
    padding-top: 30px;
    cursor pointer

    +below(700px){
      width 100px
      height 31px
      background-size: contain;
      top: -29.52px;
    }

    & .icon svg{
      +below(700px){
        height 36px
        width 36px
      }
    }
  }

  &__content{
    padding 160px 0
    +below(700px) {
      padding 120px 0
    }
  }

  &__header {
    text-align center
  }

  &__list{
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 40px 10px
    margin-top: 54px
    +below(1280px) {
      grid-template-columns repeat(2, 1fr)
      grid-gap 20px
    }
    +below(700px) {
      display flex
      flex-direction column
    }
  }
}
</style>
