<template>
  <router-link :to="{ name: 'catalog', params: { id: catalog.id } }" class="home-catalog-card">
    <div class="home-catalog-card__img">
      <div class="home-catalog-card__offers">
        <div
          v-for="(offer, i) in offers"
          :key="i"
          :class="{
            bg_pink: offer.color.code === 1,
            bg_light_gray: offer.color.code === 2,
            bg_dark_gray: offer.color.code === 3,
            bg_red: offer.color.code === 4,
            bg_white: offer.color.code === 5,
          }"
          class="home-catalog-card__offers-item"
        >
          {{ offer.title }}
        </div>
      </div>
      <ImgComponent :head_img="catalog.img_card" />
    </div>
    <div class="home-catalog-card__content">
      <div class="home-catalog-card__text">
        <h3>{{ catalog.title }}</h3>
        <span>{{ catalog.subtitle }}</span>
      </div>
      <div class="home-catalog-card__button">
        <router-link :to="{ name: 'catalog', params: { id: catalog.id } }" class="btn btn--main btn--md">
          Подробнее о курсе
        </router-link>
      </div>
    </div>
  </router-link>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "HomeCatalogCardComponent",
  components: { ImgComponent },
  props: {
    catalog: {
      type: Object,
      default: () => {},
    },
    offers: {
      type: Array,
      default: () => {},
    },
  },
  computed: {
    colors() {
      return this.$store.state._colors;
    },
  },
};
</script>

<style lang="stylus">
.home-catalog-card {
  color var(--dark)
  background white
  border-radius var(--main_radius)
  display flex
  flex-direction column
  position relative

  &:hover{
    color var(--dark) !important
  }

  &__img {
    width 100%
    height 100%
    min-height 260px
    max-height 260px
    border-radius: 10px 10px 0 0;
    +below(1100px) {
      max-height 214px
      min-height 214px
    }

    & .img {
      width 100%
      height 100%
      min-height 260px
      max-height 260px
      border-radius: 10px 10px 0 0;
      +below(1100px) {
        max-height 214px
        min-height 214px
      }
    }
  }

  & .bg_pink {
    background var(--pink)
    color var(--white)
  }

  & .bg_light_gray {
    background var(--gray-dark)
    color var(--dark-2)
  }

  & .bg_dark_gray {
    background var(--gray-4-dark)
    color var(--white)
  }

  & .bg_red {
    background var(--red)
    color var(--white)
  }

  & .bg_white {
    background var(--white)
    color var(--dark-2)
  }

  &__offers {
    display flex
    flex-wrap wrap
    justify-content flex-end
    gap 5px
    position absolute
    top: 10px;
    right: 10px
  }

  &__offers-item{
    padding 5px 10px
    border-radius var(--small_radius)

    & span {
      font-weight: 700
      font-size: 1em
      line-height 130%
    }
  }

  &__content {
    padding: 20px 30px 30px 30px
    display flex
    flex-direction column
    justify-content space-between
    height 100%
    +below(1100px) {
      padding 20px 10px 30px 10px
    }
    +below(768px) {
      padding 20px 3px 30px 10px
    }

    & span {
      color var(--gray-4-dark)
      font-weight 400
    }
  }

  &__text {
    padding-bottom: 30px
  }

  &__button {
    align-self flex-end
  }
}
</style>
