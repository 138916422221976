<template>
  <section class="home-main">
    <div class="home-main__body">
      <div class="home-main__left container">
        <h2 class="home-main__title">КУРСЫ ДЛЯ БЕРЕМЕННЫХ И НЕ ТОЛЬКО</h2>
        <h1 class="home-main__h1">Аист клуб</h1>
        <!--        <MainContentListComponent :data="data" />-->
        <SliderComponent
          class="home-main__slider"
          :paginate="false"
          :buttons="false"
          :items="data"
          :slider-options="SliderOptions"
          v-slot="{ item }"
        >
          <MainContentItemComponent :item="item" />
        </SliderComponent>
        <router-link :to="{ hash: '#catalog' }" class="btn btn--main btn--lg">Выбрать курс</router-link>
      </div>
      <div class="home-main__right">
        <ConsultationButton />
      </div>
    </div>
  </section>
</template>

<script>
// import MainContentListComponent from "./MainContentListComponent.vue";
import ConsultationButton from "components/СonsultationButton.vue";
import MainContentItemComponent from "./MainContentItemComponent.vue";
import SliderComponent from "components/SliderComponent.vue";
export default {
  name: "MainComponent",
  components: { SliderComponent, MainContentItemComponent, ConsultationButton },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      SliderOptions: {
        slidesPerView: 1.2,
        spaceBetween: 20,
        loop: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        // autoHeight: false,
        breakpoints: {
          880: {
            slidesPerView: 2.5,
          },
        },
      },
    };
  },
};
</script>

<style lang="stylus">
.home-main {
  background url("../../../..//static/images/main-bg.png")
  position: relative;
  background-attachment: fixed;

  //+below(1350px) {
  //  padding: 0 20px
  //}

  &__body{
    display flex
    //height 100vh
      +below(1280px){
        //flex-direction column
        flex-wrap wrap-reverse
      }
  }

  &__left{
    padding 200px 0 82px 0

    +below(1350px){
      padding-left: 20px
    }

    +below(1280px){
      padding 47px 0 82px 15px
    }
    +below(700px){
      padding 17px 15px 80px 15px
    }

    & .btn{
      +below(700px) {
        width 100%
      }
    }
  }

  &__right{
    background-image url("/static/images/Main.JPG")
    background-repeat no-repeat
    position: absolute;
    z-index 2
    right: 0;
    width 50%
    height 100%
    background-size contain

    +below(1280px){
      background-image url("/static/images/Main.JPG")
      position: static;
      max-height 370px;
      height 100vh
      width 100%
      background-size cover
      background-position-x center
    }

    +below(628px){
      background-image url("/static/images/Main.JPG")
      position: static;
      //max-height 211px;
      height 100vh
      width 100%
      background-position center
    }

    & .icon:hover{
      fill  var(--pink)
    }

    & .btn{
      padding 30px 50px 30px 30px
      font-size: 1em;
      gap 20px
      border-radius 10px 0 0 10px;
      position: fixed;
      right: 0;
      top: 250px;
      +below(1100px) {
      padding 30px

      span {
        display none
      }

      }
      +below(700px) {
        display none
      }

      &:hover{
        background var(--pink)
        color var(--white)
      }
    }
  }

  &__title{
    color var(--white)
    background none
    max-width 570px
    text-transform uppercase

    +below(1280px){
      max-width 940px
    }
  }

  &__h1 {
    display none
  }

  //& .slider{
  //  display none
  //
  //  +below(1280px) {
  //    display flex
  //  }
  //}

  &__slider{
    display flex

    //+below(1280px) {
    //  display flex
    //}
  }

  & .swiper{
    padding: 35px 0 47px 0
  }

  & .swiper-slide{
    padding 24px
    background #342D37
    border-radius: 5px;
    height auto
  }
}
</style>
