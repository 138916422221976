<template>
  <div class="home-main-content-item">
    <IconComponent name="bird" />
    <span>{{ item.title }}</span>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "MainContentItemComponent",
  components: { IconComponent },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="stylus">
.home-main-content-item{
  display flex
  align-items flex-start
  gap 10px

  & .icon{
    width 40px
    height 40px

    +below(620px){
      height 24px
      width: 24px;
    }
  }

  & span{
    color var(--gray-dark)
    font-weight 500
    font-size: 1em;
    line-height: 130%;
    +below(620px){
      font-size: 0.750em
    }
    +below(550px){
      max-width 207px
      font-size: 0.750em
    }
  }
}
</style>
