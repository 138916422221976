var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"home-catalog-card",attrs:{"to":{ name: 'catalog', params: { id: _vm.catalog.id } }}},[_c('div',{staticClass:"home-catalog-card__img"},[_c('div',{staticClass:"home-catalog-card__offers"},_vm._l((_vm.offers),function(offer,i){return _c('div',{key:i,staticClass:"home-catalog-card__offers-item",class:{
          bg_pink: offer.color.code === 1,
          bg_light_gray: offer.color.code === 2,
          bg_dark_gray: offer.color.code === 3,
          bg_red: offer.color.code === 4,
          bg_white: offer.color.code === 5,
        }},[_vm._v("\n        "+_vm._s(offer.title)+"\n      ")])}),0),_c('ImgComponent',{attrs:{"head_img":_vm.catalog.img_card}})],1),_c('div',{staticClass:"home-catalog-card__content"},[_c('div',{staticClass:"home-catalog-card__text"},[_c('h3',[_vm._v(_vm._s(_vm.catalog.title))]),_c('span',[_vm._v(_vm._s(_vm.catalog.subtitle))])]),_c('div',{staticClass:"home-catalog-card__button"},[_c('router-link',{staticClass:"btn btn--main btn--md",attrs:{"to":{ name: 'catalog', params: { id: _vm.catalog.id } }}},[_vm._v("\n        Подробнее о курсе\n      ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }