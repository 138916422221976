<template>
  <main class="home">
    <MainComponent :data="data" />
    <HomeCatalogListComponent :catalogs="catalogs" />
    <HomeBannerComponent />
    <ReviewsComponent title="Спасибо за ваши отзывы" :data="reviews" />
    <FaqComponent :data="faq" />
  </main>
</template>

<script>
import MainComponent from "./components/MainComponent.vue";
import HomeCatalogListComponent from "./components/HomeCatalogListComponent.vue";
import HomeBannerComponent from "./components/HomeBannerComponent.vue";
import ReviewsComponent from "components/ReviewsComponent.vue";
import FaqComponent from "components/FaqComponent.vue";
import HOME from "@/graphql/pages/home_page.graphql";

export default {
  name: "HomePage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: HOME,
      })
      .then(({ data }) => {
        store.state.FAQ = data.f_a_q_s;
        store.state.home_page.courses = data.courses_paginate;
        store.state.info = data.feedback;
        store.state.home_page.reviews = data.reviews_paginate;
      });
  },
  components: {
    FaqComponent,
    HomeBannerComponent,
    HomeCatalogListComponent,
    MainComponent,
    ReviewsComponent,
  },
  computed: {
    catalogs() {
      return this.$store.state.home_page.courses.data;
    },
    faq() {
      return this.$store.state.FAQ;
    },
    reviews() {
      return this.$store.state.home_page.reviews.data;
    },
  },
  mounted() {
    if (this.$route.hash) {
      document.querySelector(this.$route.hash).scrollIntoView({ block: "start", behavior: "smooth" });
    }
  },
  data() {
    return {
      transparency: true,
      data: [
        { title: "Подготовитесь к естественным родам: теория + практика" },
        { title: "Узнаете организацию успешного грудного вскармливания и ухода за новорожденным" },
        { title: "Овладеете техникой массажа промежности, дабы справиться без разрывов и эпизиотомии" },
        { title: "Получите рекомендации педиатра и будете спокойны за здоровье своего малыша" },
        { title: "Физическая активность повысит выносливость" },
        { title: "Восстановите после родов интимные мышцы и улучшите качество сексуальной жизни" },
      ],
    };
  },
  metaInfo() {
    return {
      title: "АИСТ | Курсы для беременных",
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: "",
        },
        {
          vmid: "description",
          name: "description",
          content: "АИСТ | Курсы для беременных",
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: "АИСТ | Курсы для беременных",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: "АИСТ | Курсы для беременных",
        },
      ],
    };
  },
};
</script>

<style lang="stylus"></style>
