<template>
  <section class="home-banner">
    <div class="home-banner__content">
      <h2 class="home-banner__title">Записаться на новости, акции и обновления клуба</h2>
      <div class="home-banner__wrapper">
        <form class="home-banner__form" @submit.prevent="submit">
          <InputComponent
            placeholder="Номер телефона"
            v-model="form.phone.value"
            :errors="form.phone.errors"
            type="tel"
            mask="+7 (###)-###-##-##"
          />
          <button :disabled="!form.checkbox.value" class="btn btn--lg btn--dark">
            <span>ПОДПИСАТЬСЯ</span>
            <!--            <LoadingIndicator title="Подписываем" v-show="loading" />-->
          </button>
        </form>
        <div class="home-banner__checkbox">
          <CheckboxComponent v-model="form.checkbox.value">
            <span>
              Соглашаюсь с
              <router-link :to="{ name: 'privacy-policy' }" class="home-banner__link"
                >политикой конфиденциальности</router-link
              >
              и
              <router-link :to="{ name: 'terms-of-use' }" class="home-banner__link"
                >условиями пользования
              </router-link>
            </span>
          </CheckboxComponent>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import POST from "@/graphql/mutations/Subscribe.graphql";
import CheckboxComponent from "components/inputs/CheckboxComponent.vue";
export default {
  name: "HomeBannerComponent",
  components: { CheckboxComponent, InputComponent },
  data() {
    return {
      phone: null,
      form: {
        phone: {
          value: "",
          required: true,
          errors: [],
        },
        checkbox: {
          value: true,
          errors: [],
        },
      },
      loading: false,
    };
  },
  watch: {
    "form.phone.value"(newValue, oldValue) {
      if (newValue.charAt(0) === "8" && oldValue.charAt(0) !== "8") {
        this.form.phone.value = "+7" + newValue.slice(1);
      }
    },
  },
  methods: {
    resetErrors() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].errors = [];
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].errors.push(err.extensions.validation[key][0]);
            }
          });
        }
      });
    },
    submit() {
      this.loading = true;
      this.resetErrors();
      this.$apollo
        .mutate({
          mutation: POST,
          variables: {
            phone: this.form.phone.value,
            // name: "Подписка на обновления",
          },
        })
        .then(() => {
          this.loading = false;
          this.$notify({
            title: "Готово, вы подписались",
            duration: 5000,
            speed: 200,
            type: "success",
          });
          this.form.phone.value = "";
        })
        .catch(({ graphQLErrors }) => {
          this.$notify({
            title: "Ошибка",
            duration: 5000,
            speed: 200,
            type: "error",
          });
          this.parseGqlErrors(graphQLErrors);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="stylus">
.home-banner{
  background: linear-gradient(0deg, rgba(236, 127, 183, 0.4), rgba(236, 127, 183, 0.4)), url('../../../../static/images/banner.jpg');
  display flex
  flex-direction column
  align-items center
  justify-content center
  background-position bottom
  background-size cover
  position: relative;

  //&::after{
  //  content: '';
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //  background: rgba(0,0,0,.2);
  //  z-index: -1;
  //}

  +below(1100px) {
    padding 0 20px
  }

  &__content{
    height 490px
    gap 30px
    display flex
    flex-direction column
    justify-content center
    align-items center
    +below(1100px) {
      padding 20px 20px
      height 337px
      gap 30px
    }
    +below(575px) {
      gap 30px
      height 310px
    }
  }

  &__form{
    display flex
    width 100%
    justify-content center
    +below(930px) {
      flex-direction column
      gap 10px
    }
    & .input{
      width 100%
      max-width 585px
      border none

      &__errors {
        color var(--white)
      }

      +below(930px) {
        max-width 100%
      }

      &__field{
        padding: 22px 30px !important
      }

      &__container{
        border-radius 5px 0 0 5px
        +below(575px) {
          border-radius 5px
        }
      }
    }
    & .btn{
      //height 100%
      height 68px
      border-radius 0 5px 5px 0
      +below(575px){
        height 65px
      }
    }
  }

  &__title{
    color var(--white)
    text-align center
    max-width 843px
    +below(1100px) {
      font-size 2.625em
      line-height 56px
    }
    +below(670px) {
      font-size 1.375em
      line-height 30px
    }
  }

  &__checkbox {
    align-self flex-start
    width 100%
    padding: 20px 0

    & span {
      color var(--white)
    }
  }

  &__link {
    color var(--white)
    text-decoration underline

    &:hover {
      color var(--white) !important
    }
  }

  &__wrapper{
    display flex
    flex-direction column
    width 100%
  }
}
</style>
